.link-footer{
    text-decoration: none;
    color: #fff;
    font-weight: 600;
}

.link-footer:hover{
    scale: 1.03;
    letter-spacing: 1.05;
}

.fa-whatsapp{
  margin-right: 20px;
}

.link-whats{
  text-decoration: none;
  color: #fff;
}

.footer {
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .footer {
      text-align: center;
    }
  
    .link-footer {
      display: block;
      margin: 0 auto;
    }
  }