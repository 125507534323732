.background-video {
  position: fixed; /* Fixa o vídeo na tela */
  top: 0;
  left: 0;
  width: 100vw; /* Largura total da tela */
  height: 100vh; /* Altura total da tela */
  object-fit: cover; /* Faz o vídeo cobrir toda a área sem distorcer */
  z-index: -2; /* Garante que o vídeo fique atrás dos outros elementos */
  /* filter: brightness(0.5); */
}
